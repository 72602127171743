import { lazy } from 'react';

const MAX_RETRIES = 3;
const DELAY = 300;
const BACKOFF_FACTOR = 2;

function getFailedUrl(error) {
  // Get failed URL from the error message
  const url = new URL(
    error.message.replace('Failed to fetch dynamically imported module: ', '').trim(),
  );

  // Add a timestamp to the url to force a refetch
  url.searchParams.set('t', Date.now());

  return url.href;
}

function delay(counter) {
  return new Promise((resolve) => {
    setTimeout(resolve, DELAY * BACKOFF_FACTOR ** counter);
  });
}

async function retry(url, counter) {
  await delay(counter);

  // Ignore the Vite dynamic import warning as the import is already statically analyzed
  // on the initial (static) import.
  return import(/* @vite-ignore */ url);
}

export default function lazyWithRetryPolicy(importer) {
  return lazy(async () => {
    try {
      return await importer();
    } catch (error) {
      for (let i = 1; i <= MAX_RETRIES; i += 1) {
        try {
          // Ignore the ESLint warning as we don't need parallelization and the solution
          // using a for loop is more readable compared to using a solution using recursion.
          // eslint-disable-next-line no-await-in-loop
          const response = await retry(getFailedUrl(error), i);

          if (response) {
            return response;
          }
        } catch {
          // Skip
        }
      }
      throw error;
    }
  });
}
